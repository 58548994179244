// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `div[data-rbd-placeholder-context-id] {
    display: none !important;
    transition: 3s !important;
}

div[data-rbd-draggable-context-id] {
    transition: 9s !important;
}

#draggingElement {
    transition: 0.001s !important;
}

#notDraggingElement {
    transition: 100000s !important;
}

#droppingElement {
    transition: 100000s !important;

}
.stick-number {
    position: absolute;
    bottom: -4rem;
    left: 50%;
    transform: translate(-50%, -50%);
    color:#fff;
}

`, "",{"version":3,"sources":["webpack://./src/components/style.css"],"names":[],"mappings":"AAAA;IACI,wBAAwB;IACxB,yBAAyB;AAC7B;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI,6BAA6B;AACjC;;AAEA;IACI,8BAA8B;AAClC;;AAEA;IACI,8BAA8B;;AAElC;AACA;IACI,kBAAkB;IAClB,aAAa;IACb,SAAS;IACT,gCAAgC;IAChC,UAAU;AACd","sourcesContent":["div[data-rbd-placeholder-context-id] {\n    display: none !important;\n    transition: 3s !important;\n}\n\ndiv[data-rbd-draggable-context-id] {\n    transition: 9s !important;\n}\n\n#draggingElement {\n    transition: 0.001s !important;\n}\n\n#notDraggingElement {\n    transition: 100000s !important;\n}\n\n#droppingElement {\n    transition: 100000s !important;\n\n}\n.stick-number {\n    position: absolute;\n    bottom: -4rem;\n    left: 50%;\n    transform: translate(-50%, -50%);\n    color:#fff;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
